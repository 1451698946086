import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import configData from './config.json';

function Household() {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    //const location = useLocation();
    //const { token } = location.state || {};   
  
    useEffect(() => {
        const fetchData = async () => {            
            try {
                

                const token = sessionStorage.getItem('token'); 
                if (!token) { 
                    throw new Error('Token not found in storage');
                }          
                //console.log('Token:', token); // Log the token value
   

                const response = await fetch(`${configData.SERVER_URL}/household`, {                    
                    headers: {
                        'Authorization': `Bearer ${token}`                                                
                    }                    
                });
                
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                const jsonData = await response.json();
                setData(jsonData.data);
               //console.log(jsonData);
            } catch (error) {
                console.error('Error fetching data', error);
                setError(error.message);
                throw error;
            }
        };
        fetchData();
    }, []);

    function navigateToHousholdContainer(householdId) {
        //householdId = item.id;
        //console.log(householdId);
        navigate(`/household/${householdId}/container`, {state: { householdId }});
        //const householdId = {
        //    id: item.id
        //}
        //sessionStorage.setItem('householdId', householdId.id)
        // navigate('/container');
    }  

    return (
        <div className="bg-brand-default min-h-screen">
            <div className="w-screen flex flex-row justify-center items-center py-12 px-10">                
                {Array.isArray(data) && data.map((item) => (
                <div key={item.id} onClick={() => navigateToHousholdContainer(item.id)} className="mx-2 bg-white flex flex-col px-6 items-center border  border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                        {/* <a href="#" key={item.id} class="bg-blue-500">
                        <img class="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-s-lg" src="" alt=""/>*/}
                    <div className="flex flex-col justify-between p-4 leading-normal">
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{item.name}</h5>                       
                        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p>
                    </div>
                </div>        
                ))}
            </div>
        </div>
    )
}

export default Household;