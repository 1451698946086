import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Login';
import Household from './Household';
import Container from './Container';
import ContainerDetails from './ContainerDetails';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="household/" element={<Household />} />

        <Route path="household/:householdId/container" element={<Container />} />
        <Route path="household/:householdId/container/:containerId" element={<ContainerDetails />} />
      </Routes>
    </Router>
  );
}

export default App;
