import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import configData from './config.json';
import ReactPaginate from "react-paginate";
import moment from "moment";

function Container() {
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    //const { token } = location.state || {};   
    const { householdId } = location.state || {};   

    const [formatExpiryDate, setFormatExpiryDate] = useState('');
    const [newFormat, setNewFormat] = useState('');


    const [items, setItems] = useState([]);
    const [pageCount, setPageCount ] = useState(0);

    useEffect(() => {

        const getData = async () => {
            const token = sessionStorage.getItem('token'); 

            const respond = await fetch(`${configData.SERVER_URL}/household/${householdId}/container`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }                
            });

            if (!token) {
                throw new Error('Token not found in storage');
            }
            if (!householdId) {
                throw new Error('Household ID not found in storage')
            }    

            const jsonData = await respond.json();
            setItems(jsonData.data);
            
            const pageTotal = jsonData.recordsTotal;
            setPageCount(Math.ceil(pageTotal/10));

         //   const expiryDate = jsonData.data.expiryDate;

            setItems(previousItems => previousItems.map(item => {
                if (item.expiryDate) {
                    return {...item, newFormat: moment(item.expiryDate).format("DD/MM/YYYY")}
                } else {
                    return {...item}
                }                
            }))
        
            setNewFormat(formatExpiryDate);

        };

        getData();        

    }, []);

    const fetchData =  async (currentPage) => {
        try {
            const token = sessionStorage.getItem('token');         
           
           const endLength = 10;
           const startLength = ((currentPage) - 1) * endLength;

            const response = await fetch(`${configData.SERVER_URL}/household/${householdId}/container?Start=${startLength}&length=${endLength}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }                
            });
            
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();
            return data.data;
    
        } catch (error) {
            setError(error.message);
            throw error;
        }
    };

    const navigateToContainerDetail = (householdId, containerId) => {   
        navigate(`${containerId}`, {state: { householdId, containerId }})       
    }

    const handlePageClick = async (data) => {
        const currentPage = data.selected + 1;        
        const commentsFromServer = await fetchData(currentPage);
        setItems(commentsFromServer);
    }

    return (
        <div className="bg-brand-default min-h-screen">            
            <div className="w-screen flex flex-wrap -mx-2 items-center py-12 px-10">                
                    {Array.isArray(items) && items.map((container) => (
                    <div key={container.id} onClick={() => navigateToContainerDetail(householdId, container.id)} className="w-full md:w-1/3 mb-4 mx-2 bg-white flex flex-col px-6 items-center border  border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                        <div className="flex flex-col p-4 leading-normal">
                            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{container.name}</h5>                       
                            <h5 className="mb-3 font-normal text-gray-700 dark:text-gray-400">Quantity: {container.quantity}</h5>
                           <h5 className="mb-3 font-normal text-gray-700 dark:text-gray-400">Expiry Date: {(container.expiryDate == null) ? "N/A" : container.newFormat }</h5>
                        </div> 
                    </div>
                ))}
            </div>
            
            <nav className="w-screen flex  -mx-2 justify-center items-center py-12 px-10">
                <ReactPaginate 
                    previousLabel={'prev'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={' align-middle	inline-flex  text-sm justify-center items-center'}
                    pageClassName={' px-3 h-8  text-gray-500 bg-white border border-e-20 border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'}
                 //   pageLinkClassName={'bg-yellow items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'}
                    previousClassName={'items-center justify-center px-3 h-8 ms-0  text-gray-500 bg-white border border-s-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'}
                    nextClassName={'items-center justify-center px-3 h-8 ms-0  text-gray-500 bg-white border border-e-0 border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'}
                  //  nextLinkClassName={'bg-yellow items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'}
                    breakClassName={'bg-yellow items-center justify-center px-3 h-8 ms-0 text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'}
                    //breakLinkClassName
                    activeClassName={'red items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white'}
                />

            </nav>
           
        </div>
        
    )

}    
   
export default Container;