import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import configData from './config.json';

function ContainerDetails() {
    const [data, setData] = useState('');
    const [error, setError] = useState(null);
    //const navigate = useNavigate();
    const location = useLocation();

    const { householdId } = location.state || {};   
    const { containerId } = location.state ;   


    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    //const [categories, setCategories] = useState(''); 
    const [quantity, setQuantity] = useState('');
    const [fixedQuantity, setFixedQuantity] = useState('');
    const [expiryDay, setExpiryDay] = useState('');
    const [unitPrice, setUnitPrice] = useState('');
    var jsonData;

    //expiry date
    const [expiryDate, setExpiryDateUpdate] = useState('');
    //expiry true/false
    const [setExpiryDate, setIsExpiryDate] = useState('');
    //const [expiryDateRemoveBtn, setExpiryDateRemoveBtn] = useState('');

    //change default value
    const [defaultvalue, setDefaultValue] = useState('');

    //remove button
    const [removeBtn, setRemoveBtn] = useState('');


    useEffect(() => {
        const fetchData =  async () => {
            try {
                console.log('expirydate' + data.expiryDate);
                console.log('createdDate' + data.createdDate);
               // console.log(householdId + 'EEEEE' + containerId)
                //const householdId = sessionStorage.getItem('householdId'); 
                //const containerid = sessionStorage.getItem('containerid');
                
                const token = sessionStorage.getItem('token'); 

                if (!token) {
                    throw new Error('Token not found in storage');
                }             
               
             //   console.log('containerid???  ' + containerid) ; 

                const response = await fetch(`${configData.SERVER_URL}/household/${householdId}/container/${containerId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }                    
                });
                
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                const jsonData = await response.json();
                setData(jsonData.data);
              //  console.log(jsonData.data);
            } catch (error) {
              //  console.error('Error fetching data', error);
                setError(error.message);
                throw error;
            }
        }; 
        fetchData();
        
        handleFieldValue();
    }, []);

    function handleFieldValue()
    {
        setIsExpiryDate(false);
        setName(data.name);
        setDescription(data.description);
        setQuantity(data.quantity);
        setFixedQuantity(data.fixedQuantity);
        setExpiryDateUpdate(data.expiryDate);
        setExpiryDay(data.expiryDay);
        setUnitPrice(data.unitPrice);
        setRemoveBtn(false);
    } 

    const callApi = () =>  {                 
        const containerDetailsUpdate = {name, description, quantity, fixedQuantity, expiryDate, expiryDay, unitPrice, setExpiryDate};  
        const token = sessionStorage.getItem('token'); 

        console.log(data)
        fetch(`https://localhost:7135/household/${householdId}/container/${containerId}/update`, 
        {
          method: 'post',
          headers: {'Content-Type':'application/json', 'Authorization': `Bearer ${token}`},
          body: JSON.stringify(containerDetailsUpdate)
        })

        console.log(data);
        console.log(description + "desc")

        //console.log(householdId + ":Zaaaaazzzzzzzzzzzz:" + containerId)
        //const containerId = {
        //    id: item.id
        //}
        //console.log(containerId);
        //sessionStorage.setItem('clickedItemData', containerId.id)
        //navigate('/item');     
    }  
    
    const handleNameChange = (event) => {
        setName(event.target.value);
    }

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    }

    const handleQuantityChange = (event) => {
        setQuantity(event.target.value);
    }

    const handleFixedQuantityChange = (event) => {
        setFixedQuantity(event.target.value);
    }

    const handleExpiryDayChange = (event) => {
        setExpiryDay(event.target.value);
    }

    const handleUnitPriceChange = (event) => {
        setUnitPrice(event.target.value);
    }

    const handleExpiryDateChange = (event) => {
        //add if else
       
        if (setExpiryDate != null) {
            setIsExpiryDate(true);
            setExpiryDateUpdate(event.target.value);
            console.log(expiryDate + "::::::::::::::: expiry date  :::::::::: true>" + setExpiryDate)
        } 
        else {
            setIsExpiryDate(false);
            console.log(expiryDate + "::::::::::::::: expiry date  :::::::::: false>" + setExpiryDate)
        }
    }

    const handleExpiryDateRemoveBtn = (event) => {
        //change the default value to null
        
        setRemoveBtn(true);
        setIsExpiryDate(true);
        setExpiryDateUpdate(null);
        console.log(expiryDate + "::::::::::::::: expiry date  :::::::::: false>" + setExpiryDate)


    }

    return (
        <div className="bg-brand-default min-h-screen">
            <div className="w-screen flex flex-row justify-center items-center py-12 px-10">      
                <div className="mx-2 bg-white flex flex-col px-6 items-center border  border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                        {/* <a href="#" key={item.id} className="bg-blue-500">
                        <img className="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-s-lg" src="" alt=""/>*/}
                    <div className="w-screen flex flex-col justify-between p-4 leading-normal">
                        
                        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Name:</h5>
                        <input name="name" defaultValue={data.name}
                        onChange={handleNameChange}
                        placeholder='Name' type="text" class="mb-3 block w-[500px] px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                        focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"/>

                        <p className="font-normal text-gray-700 dark:text-gray-400">Label:</p>
                        <input name="label" value={data.label} disabled readOnly
                        placeholder='label' type="text" class="mb-3 block w-[500px] px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                        focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"/>

                        <p className="font-normal text-gray-700 dark:text-gray-400">Created Date:</p>
                        <input name="createdDate" 
                        value={data.createdDate} disabled readOnly
                         type="datetime-local" class="mb-3 block w-[500px] px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                        focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"/>

                        <p className="font-normal text-gray-700 dark:text-gray-400">Description:</p>
                        <input name="description" defaultValue={data.description} 
                        onChange={handleDescriptionChange}
                        placeholder='description' type="text" class="mb-3 block w-[500px] px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                        focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"/>

                        <p className="font-normal text-gray-700 dark:text-gray-400">Category:</p>
                        <input name="categories" defaultValue={data.categories}
                        placeholder='categories' type="text" class="mb-3 block w-[500px] px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                        focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"/>

                        <p className="font-normal text-gray-700 dark:text-gray-400">Quantity:</p>
                        <input name="quantity" defaultValue={data.quantity}
                        onChange={handleQuantityChange}
                        placeholder='quantity' type="text" class="mb-3 block w-[500px] px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                        focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"/>

                        <p className="font-normal text-gray-700 dark:text-gray-400">Fixed Quantity:</p>
                        <input name="fixedQuantity" defaultValue={data.fixedQuantity}
                        onChange={handleFixedQuantityChange}
                        placeholder='fixedQuantity' type="text" class="mb-3 block w-[500px] px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                        focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"/>

                        <p className="font-normal text-gray-700 dark:text-gray-400">Expiry Date:</p>
                        <div className="w-[500px] flex justify-between leading-normal">

                            <input name="expiryDate" 
                            defaultValue={removeBtn ? null : data.expiryDate}
                            onSelect={handleExpiryDateChange}                
                            placeholder='description' type="datetime-local" class="mb-3 block w-[460px] mr-1 px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                            focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"/>

                            <button type="button" onClick={handleExpiryDateRemoveBtn}
                            className="text-red ml-1 mb-3 w-[40px] bg-white border border-slate-300 rounded-md  shadow-sm focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500">
                            x</button>
                        
                        </div>

                        <p className="font-normal text-gray-700 dark:text-gray-400">Expiry Day:</p>
                        <input name="expiryDay" defaultValue={data.expiryDay}
                        onChange={handleExpiryDayChange} 
                        placeholder='expiryDay' type="text" class="mb-3 block w-[500px] px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                        focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"/>

                        <p className="font-normal text-gray-700 dark:text-gray-400">Unit Price:</p>
                        <input name="unitPrice" defaultValue={data.unitPrice}
                        onChange={handleUnitPriceChange}
                        placeholder='unitPrice' type="text" class="mb-3 block w-[500px] px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                        focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"/>

                        <div className="py-5 flex justify-center items-center">
                            <div type='submit' onClick={callApi}  class="flex justify-center items-center bg-teal-300 hover:bg-teal-500 text-white font-bold  rounded-full w-[100px] h-[40px]">
                                Update!
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    )

}    
   
export default ContainerDetails;