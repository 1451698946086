import React from 'react';

function Modal({ message, onClose }) {
  return (
    //<div class='mt-1 flex items-center w-[500px] px-3 py-2 bg-red border border-slate-300 rounded-md text-sm shadow-sm'>
      <div class=''>
      {/*  <span className="close" onClick={onClose} class='flex text-right'>&times;</span> */}
        <p class='text-red-600'>{message}</p>
      </div>
    //</div>
  );
}

export default Modal;
