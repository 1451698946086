import { useEffect, useState } from 'react';
import './App.css';
import Modal from './Modal.js';
import { useNavigate, useParams } from 'react-router-dom';
import configData from './config.json';


function Login() {

  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [loginPrompt, setLoginPrompt] = useState('');
  const [showModal, setShowModal] = useState(false);
  const concurrentUser = true; 
  const navigate = useNavigate();

  function callApi(token) {
    const credentialObj = {emailAddress, password, concurrentUser}    
    fetch(`${configData.SERVER_URL}/user/login`, 
    {
      method: 'post',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify(credentialObj)
    })
    .then(response => {
      if (response.ok) {
        response.json().then(data => {
        token = data.data.accessToken; 
          navigate('household/');
          setShowModal(false);
          sessionStorage.setItem('token', data.data.accessToken);
        })        
    } else {
      response.json().then(data => {
        setLoginPrompt(data.message || 'Connection error. Please try again later.')
        setShowModal(true);
      })
      .catch(error => {
        setLoginPrompt('An error occurred. Please try again later.');
        setShowModal(true);
      });
    }})
  }

  const handleEmailAddressChange = (event) => {
    setEmailAddress(event.target.value);
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    callApi();
  }  

  return (
    <>
      <div className="bg-brand-default min-h-screen w-screen flex justify-center items-center">
        <div className="w-screen text-center justify-center items-center">                

          {/*<h1 className="text-5xl pt-12  py-5 text-center">HiStorage! </h1>*/}

          <h1 className="text-5xl my-12 text-center">Login!</h1>
          <div className=" flex justify-center items-center my-4">
            {showModal && <Modal message={loginPrompt} onClose={() => setShowModal(false)} />}
          </div>

              <div className="my-5 flex flex-col justify-center items-center">
                <span className="my-1 text-xl font-medium text-slate-700">Username: </span>
                <input name="emailAddress" value={emailAddress} onChange={handleEmailAddressChange}             
                  placeholder='Email Address' type="text"   className="mt-1 text-center md:w-1/3 w-2/4 px-2 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                  focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                  disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                  invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"/>
              </div>

              <div className="my-5 text-center flex flex-col justify-center items-center">
                <span className="my-1   text-xl font-medium  text-slate-700">Password: </span>
                <input name="password" value={password} onChange={handlePasswordChange}
                placeholder='Password'
                type="password"
                className="mt-1 text-center w-2/4 md:w-1/3 px-2 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                  focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                  disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                  invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"/>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="my-12 flex justify-center items-center">
                  <div type='submit' onClick={callApi} className="py-2 bg-teal-300 hover:bg-teal-500 text-white font-bold  rounded-full w-[100px] h-[40px]">
                    Login!
                  </div>
                </div>
              </form>

        </div>
      </div>     
    </>  
  );
}

export default Login;
